@media print {

    body, main {
        border: 0;
        float: none;
        margin: 0;
        overflow: visible;
        padding: 0;
        position: static;
        width: 100%;
    }

    header, footer, aside, nav, form, iframe, .menu, .hero, .adslot {
        display: none;
    } {
    background-image: none !important;
}

    img, svg {
        display: none !important;
    }

    img.print, svg.print {
        display: block;
        max-width: 100%;
    }

    img.dark {
        filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
    }

    a::after {
        content: " (" attr(href) ")";
    }

    main::after {
        display: block;
        text-align: center;
    }

    table, img, svg {
        break-inside: avoid;
    }

    /* target all pages */
    @page {
        /* margin: 2cm;*/
        size: portrait;
        width: 100%;
        margin: 1cm 1cm 1cm 0.5cm;
        padding: 10px;
    }

    .no-print, .no-print * {
        display: none !important;
        -webkit-print-color-adjust: exact;
    }

}