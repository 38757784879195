
.ds-0 {
    box-shadow: none !important
}

.ds-1 {
    box-shadow: 0 0 50px 10px rgba(0, 0, 0, .1)
}

.ds-2 {
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, .5)
}

.ds-2a {
    box-shadow: 0 10px 5px 0 rgba(0, 0, 0, .5)
}

.ds-2b {
    box-shadow: -10px 10px 5px 0 rgba(0, 0, 0, .5)
}

.ds-3 {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .5)
}

.ds-4 {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .5)
}

.ds-5 {
    box-shadow: 0 0 8px rgba(0, 0, 0, .23)
}

.ds-6 {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.ds-7 {
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
    transition: .25s box-shadow
}

.ds-7:focus, .ds-7:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.ds-8 {
    overflow: hidden;
    transition: .25s box-shadow
}

.ds-8:focus, .ds-8:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15)
}

.ds-9 {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.ds-10 {
    overflow: hidden;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .16), 0 1px 2px 0 rgba(0, 0, 0, .12);
    transition: .25s box-shadow
}

.ds-10:focus, .ds-10:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .18), 0 2px 5px 0 rgba(0, 0, 0, .15)
}


.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem
}

.bd-callout h4 {
    margin-top: 0;
    margin-bottom: .25rem
}

.bd-callout p:last-child {
    margin-bottom: 0
}

.bd-callout code {
    border-radius: .25rem
}

.bd-callout+.bd-callout {
    margin-top: -.25rem
}

.bd-callout-primary {
    border-left-color: #007bff
}

.bd-callout-primary h4 {
    color: #007bff
}

.bd-callout-info {
    border-left-color: #5bc0de
}

.bd-callout-info h4 {
    color: #5bc0de
}

.bd-callout-warning {
    border-left-color: #ffc107
}

.bd-callout-warning h4 {
    color: #ffc107
}

.bd-callout-danger {
    border-left-color: #d9534f
}

.bd-callout-danger h4 {
    color: #d9534f
}