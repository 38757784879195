.dropzone {
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
    height: 100px;
    width: 400px;
    border: 2px dashed black;
    border-radius: 20px;
    outline: none;
}

#box {
    position: relative;
    width: 140px;
    height: 140px;
    padding: 10px; 
    margin-left: 61px;
    background-color: white; 
}

#icon {  
    padding: 10px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -20px;
    top: -10px;
}

.dropzone .dropHeading {
    margin-top: 12px;
}

@media only screen and (max-width: 700px) {
    .dropzone {
        background: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        padding: 5px;
        cursor: pointer;
        height: 50px;
        width: 340px;
        border: 2px dashed black;
        outline: none;
    }
}
